.activeTab {
  background-color: #7a1b1b;
  /* Change this to the desired color */
  color: #ffffff;
  /* Change this to the desired color */
}

.custom-carousel .carousel-indicators li {
  background-color: goldenrod !important;
  /* Set the background color to golden */
  /* Additional styling if needed */
}

.active-link {
  color: yellow;
  /* Add any additional styles for the active link */
}